<template>
  <div class="verify-alert z-index-top" v-if="isShow">
    <div class="verify-alert-card">
      <div class="verify-alert-card-top">
        <p class="verify-alert-card-top-title">{{ titleContent }}</p>
        <p class="verify-alert-card-top-info" v-if="notAdministrator">
          {{ managerName }} {{ managerPhone }}
        </p>
      </div>
      <div class="verify-alert-card-middle" v-if="notAdministrator">
        <login-input class="verify-alert-card-middle-input" type="code" :icon="inputIcon" placeholder="验证码"
          @onInput="onCodeInput" />
        <p class="verify-alert-card-middle-button" :class="{ disable: isCountingDown }" @click="onSendCodeClick">
          {{ codeButtonTitle }}
        </p>
      </div>
      <div class="verify-alert-card-blank" :style="{ height: `${notAdministrator ? 0 : 60}px` }"></div>
      <div class="verify-alert-card-bottom">
        <button class="verify-alert-card-bottom-cancel" @click="onCancelClick">
          取消
        </button>
        <button class="verify-alert-card-bottom-confirm" @click="onConfirmClick">
          确认
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import LoginInput from "@c/login/login-input.vue";
import { CountdownTool } from "@js/countdown-tool.js";

export default {
  components: {
    LoginInput,
  },

  props: {
    isShow: false,
    managerName: undefined,
    managerPhone: undefined,
  },

  data() {
    return {
      inputIcon: require("@imgs/login-msg.png"),
      code: "",
      countDownNumber: 0,
      currentCountDownNumber: 0,
      notAdministrator: true,
      titleContent: "操作验证"
    };
  },

  computed: {
    info() {
      `${this.managerName} ${this.managerPhone}`;
    },

    codeButtonTitle: function () {
      return this.countDownNumber === 0
        ? "获取验证码"
        : this.countDownNumber + "s";
    },

    isCountingDown: function () {
      return this.countDownNumber != 0;
    },
  },

  watch: {
    isShow(newValue) {
      if (newValue) {
        this.code = null
      }
    }
  },

  mounted() {
    if (this.$store.state.accountInfo.roleId == 14) {
      this.notAdministrator = false
      this.titleContent = "确认修改"
    }
  },
  methods: {
    onCodeInput(value) {
      this.code = value;
    },

    onCancelClick: function () {
      this.$emit("onCancelClick");
    },

    onConfirmClick: function () {
      if (this.$store.state.accountInfo.roleName == '高级系统管理员') {
        this.$emit("onConfirmClick", this.code);
      } else {
        if (!this.code) {
          this.$toast.showRed(MSG.verifyCodeEmpty);
        } else {
          this.$emit("onConfirmClick", this.code);
        }
      }
    },

    onSendCodeClick: function () {
      if (this.isCountingDown === true) {
        return;
      }
      this.sendVerifyCodeRequest();
    },

    async sendVerifyCodeRequest() {
      try {
        const params = {
          "toPhone": this.managerPhone,
          "smsType": 9 //八戒睡眠登录
        };
        const _ = await this.$api.sendVerifyCode(params);
        this.$toast.showGreen(MSG.verifyCodeSendSucceed);
        this.countDown();
      } catch (error) {
        this.$toast.showRed(error);
      }
    },

    countDown() {
      this.countDownNumber = window.MSG_LIMIT_SECOND;
      CountdownTool.countDown({
        remainSeconds: window.MSG_LIMIT_SECOND,
        trigger: (remainSeconds) => {
          this.countDownNumber = remainSeconds;
        },
        over: () => {
          this.countDownNumber = 0;
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.verify-alert {
  background-color: rgba($color: #666666, $alpha: 0.2);
  position: fixed;
  top: rem(0);
  left: rem(0);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &-card {
    background-color: white;
    border-radius: rem(3);
    box-shadow: rem(0) rem(0) rem(15) rem(0) rgba(black, 0.1);
    width: rem(380);

    &-top {
      width: 100%;
      box-sizing: border-box;
      padding-top: rem(24);
      padding-left: rem(20);

      &-title {
        color: #4d4f5c;
        font-size: rem(18);
        line-height: rem(24);
      }

      &-info {
        color: rgba($color: #4d4f5c, $alpha: 0.5);
        font-size: rem(14);
        line-height: rem(20);
        margin-top: rem(16);
      }
    }

    &-blank {
      width: 100%;
    }

    &-middle {
      width: 100%;
      height: rem(112);
      display: flex;
      align-items: center;

      &-input {
        width: rem(217);
        height: rem(44);
        margin-left: rem(20);
      }

      &-button {
        color: #505659;
        background-color: white;
        font-size: rem(15);
        border: 1px solid #d9d9d9;
        border-radius: rem(3);
        width: rem(113);
        height: rem(42);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: rem(10);
      }

      .disable {
        opacity: 0.65;
      }
    }

    &-bottom {
      width: 100%;
      height: rem(50);
      display: flex;

      &-cancel {
        color: white;
        background-color: #0D3F77;
        font-size: rem(16);
        border-bottom-left-radius: rem(3);
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &-confirm {
        color: white;
        background-color: #325FAC;
        font-size: rem(16);
        border-bottom-right-radius: rem(3);
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>