<template>
  <div class="login-input">
    <img :src="icon" />
    <input
      :type="type"
      :placeholder="placeholder"
      v-model.trim="value"
      @input="onInput"
    />
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
    },

    type: {
      type: String,
    },

    placeholder: {
      type: String,
    },
  },

  data: function () {
    return {
      value: "",
    };
  },

  methods: {
    onInput: function () {
      this.$emit("onInput", this.value);
    },
  },
};
</script>

<style lang="scss" scoped>


.login-input {
  background-color: white;
  border: rem(1) solid #d9d9d9;
  border-radius:rem(3);
  box-sizing: border-box;
  padding-left: rem(18);
  padding-right: rem(18);
  display: flex;
  align-items: center;

  img {
    width: rem(18);
  }

  input {
    color: #666666;
    font-size: rem(16);
    border: none;
    outline: none;
    width: 100%;
    margin-left: rem(9);
  }

  input::-webkit-input-placeholder {
    color: #c1c1c1;
    font-size: rem(16);
  }
}
</style>