<template>
  <div class="setting">
    <item-selector class="setting-item-selector" :titleArray="selectTitleArray"
      @onselectedClick="onItemSelectorselectedClick" />
    <component style="min-height: 500px;" :is="currentComponent" ref="component" @onSaveClick="onSaveClick"
      @onApplyClick="onApplyClick"></component>
    <verify-alert v-if="leaderInfo" :is-show="isShowVerifyAlert" :managerName="leaderInfo.trueName"
      :managerPhone="leaderInfo.mobile" @onConfirmClick="onAlertConfirmClick"
      @onCancelClick="onAlertCancelClick"></verify-alert>
  </div>
</template>

<script>
import ItemSelector from "@c/setting/item-selector.vue";
import VerifyAlert from "@c/setting/verify-alert/verify-alert.vue";
export default {
  components: {
    ItemSelector,
    DevelopConfiguration: () =>
      import("@c/setting/develop-configuration/develop-configuration.vue"),
    SleepReportConfiguration: () => import("@c/setting/sleep-report-configuration/sleep-report-configuration.vue"),
    BloodOxygenReportConfiguration: () => import("@c/setting/blood-oxygen-report-configuration/blood-oxygen-report-configuration.vue"),
    OrganizationConfiguration: () =>
      import(
        "@c/setting/organization-configuration/organization-configuration.vue"
      ),
    PushConfiguration: () => import("@c/setting/push-configuration/push-configuration.vue"),
    VerifyAlert
  },

  data() {
    return {
      //模块切换
      selectTitleArray: ["机构设置", "睡眠报告设置", "睡眠初筛报告设置", "开发配置", "推送配置"],
      currentComponent: "organization-configuration",
      //权限校验
      isShowVerifyAlert: false,
      leaderInfo: undefined,
      saveCount: 0
    };
  },

  computed: {
    hospitalID: function () {
      return this.$store.state.selectedHospital.hospitalid;
    },
  },

  watch: {
    hospitalID() {
      this.getLeaderInfo()
    }
  },

  mounted() {
    this.getLeaderInfo()
  },

  methods: {
    onItemSelectorselectedClick(index) {
      switch (this.selectTitleArray[index]) {
        case "机构设置":
          this.currentComponent = "organization-configuration";
          break;
        case "睡眠报告设置":
          this.currentComponent = "sleep-report-configuration";
          break;
        case "睡眠初筛报告设置":
          this.currentComponent = "blood-oxygen-report-configuration";
          break;
        case "开发配置":
          this.currentComponent = "develop-configuration";
          break;
        case "推送配置":
          this.currentComponent = "push-configuration";
          break;
        default:
          break;
      }
    },

    onSaveClick() {
      if (this.leaderInfo) {
        this.isShowVerifyAlert = true
      } else {
        this.$toast.showRed("没有可用的管理员，请联系客服")
      }
    },

    onApplyClick() {
      if (this.leaderInfo) {
        this.isShowVerifyAlert = true
      } else {
        this.$toast.showRed("没有可用的管理员，请联系客服")
      }
    },

    async getLeaderInfo() {
      try {
        const params = {
          "hospitalId": this.hospitalID
        };

        const data = await this.$api.getLeaderInfo(params);
        this.leaderInfo = data
      } catch (error) {
        this.leaderInfo = undefined
      }
    },

    //verify-alert
    onAlertConfirmClick(code) {
      this.isShowVerifyAlert = false;
      this.verifyRequest(code);
    },

    onAlertCancelClick() {
      this.isShowVerifyAlert = false;
      let component = this.$refs.component;
      component.getInfo()
    },

    async verifyRequest(code) {
      try {
        if (this.$store.state.accountInfo.roleName != '高级系统管理员') { //校验
          const params = {
            "phone": this.leaderInfo.mobile,
            "verificationCode": code
          };
          await this.$api.smsVerification(params);
        }
        let component = this.$refs.component;
        switch (component.name) {
          case "organization":
            component.handleApply()
            break
          default:
            component.saveInfo()
            this.$emit("saveInfo", this.saveCount += 1);
            break
        }
      } catch (error) {
        this.$toast.showRed(error);
      }
    },


  },
};
</script>

<style lang="scss" scoped>
.setting {
  background-color: #eff2f7;
  width: 100%;
  height: 100%;
  position: relative;

  &-item-selector {
    position: absolute;
    top: rem(43);
    left: rem(36);
  }
}
</style>