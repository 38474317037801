class CountdownTool {
  
  static countDown({
    remainSeconds: remainSeconds,
    trigger: trigger,
    over: over
  }) {
    let timer = setInterval(() => {
      remainSeconds--;
      trigger(remainSeconds);
      if (remainSeconds == 0) {
        clearInterval(timer);
        over();
      }
    }, 1000);
  }
}

export { CountdownTool };
