<template>
  <div class="item-selector  z-index-second">
    <button
      class="item-selector-item"
      v-for="(item, index) of titleArray"
      :key="index"
      :class="{ active: currentIndex == index }"
      @click="onselectedClick(index)"
    >
      {{ item }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    titleArray: Array,
  },

  data() {
    return {
      currentIndex: 0,
    };
  },

  methods: {
    onselectedClick(index) {
      this.currentIndex = index;
      this.$emit("onselectedClick", this.currentIndex);
    },
  },
};
</script>

<style lang="scss" scoped>


.item-selector {
  display: flex;

  &-item {
    color: #666666;
    font-size: rem(14);
    border-bottom: rem(2) solid;
    border-bottom-color: #e8e8e8;
    min-width: rem(90);
    padding: rem(0) rem(10);
    height: rem(32);

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .active {
    color: #325FAC;
    border-bottom-color: #325FAC;
  }
}
</style>